import Swiper from 'swiper'
import { A11y, Autoplay, Pagination } from 'swiper/modules'
// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/pagination'

const swiper = new Swiper('.swiper', {
  modules: [A11y, Autoplay, Pagination],
  a11y: {
    enabled: true,
    scrollOnFocus: false,
  },
  slidesPerView: 1,
  loop: true,
  autoplay: {
    delay: 5000,
    pauseOnMouseEnter: true,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>'
    },
  },
})
